import { createContext, useEffect, useReducer } from 'react';
import useUserStore from '../app/user';

const initialState = {
  isOnline: true,
};

const handlers = {
  INITIALIZE: (state) => ({ ...state, isOnline: navigator.onLine }),
  SET_ONLINE: (state) => ({ ...state, isOnline: true }),
  SET_OFFLINE: (state) => ({ ...state, isOnline: false }),
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const OnlineContext = createContext({
  ...initialState,
});

function OnlineProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { site } = useUserStore();

  useEffect(() => {
    const setOnline = () => {
      if (navigator.connection.rtt < site.rtt && !(navigator.connection.rtt === 0)) dispatch({ type: 'SET_ONLINE' });
    };

    const setOffline = () => {
      if (navigator.connection.rtt > site.rtt || navigator.connection.rtt === 0) dispatch({ type: 'SET_OFFLINE' });
    };
    function logNetworkInfo() {
      setOnline();
      setOffline();
    }
    dispatch({ type: 'INITIALIZE' });

    window.addEventListener('online', setOnline);

    window.addEventListener('offline', setOffline);

    navigator.connection.addEventListener('change', logNetworkInfo);

    logNetworkInfo();

    return () => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
      navigator.connection.removeEventListener('change', logNetworkInfo);
    };
  }, [site]);

  return <OnlineContext.Provider value={state}>{children}</OnlineContext.Provider>;
}

export { OnlineContext, OnlineProvider };
