import React, { useState } from 'react';
import { IoMdArrowRoundForward } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import useUserStore from '../app/user';

const SiteUrl = () => {
  const { site, setSiteUrl, setSiteName, setSiteInstance, setSiteRtt } = useUserStore();

  const [url, setUrl] = useState(site.url);
  const [instanceName, setInstanceName] = useState(site.instance);
  const [companyName, setCompanyName] = useState(site.name);
  const [webParamsRtt, setWebParamsRtt] = useState(site.rtt);
  const navigate = useNavigate();

  const handleClick = () => {
    setSiteName(companyName);
    setSiteUrl(url);
    setSiteInstance(instanceName);
    setSiteRtt(webParamsRtt);
    navigate('/login');
  };

  return (
    <div className=' w-screen fixed top-0 z-30'>
      <div className='imgs w-full flex justify-between items-center'>
        <img src='/images/logo.png' className='w-[12rem] h-20 object-cover ' alt='B tech name company' />
        <img src='/images/initalt-pic2.jpg' className='w-[8rem]' alt='initial-pic' />
      </div>

      <div className='w-4/5 mx-auto'>
        <div className='psdwrapper flex flex-col gap-2 mt-[4rem]'>
          <div className='flex flex-col py-1'>
            <label className='font-medium'>Site Url</label>
            <input
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              className='p-2 border border-gray-800 rounded text-sm'
              placeholder='Ndrysho Adrese / Change Site URL'
            />
          </div>

          <div className='flex flex-col py-1'>
            <label className='font-medium'>Instance Name</label>
            <input
              value={instanceName}
              onChange={(e) => setInstanceName(e.target.value)}
              className='p-2 border border-gray-800 rounded text-sm'
              placeholder='Instance Name'
            />
          </div>

          <div className='flex flex-col py-1'>
            <label className='font-medium'>Company Name</label>
            <input
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              className='p-2 border border-gray-800 rounded text-sm'
              placeholder='Company Name Parameter'
            />
          </div>

          <div className='flex flex-col py-1'>
            <label className='font-medium'>RTT</label>
            <input
              value={webParamsRtt}
              onChange={(e) => setWebParamsRtt(e.target.value)}
              className='p-2 border border-gray-800 rounded text-sm'
              placeholder='Web Params RTT'
            />
          </div>

          {/* icons */}
          <button className='h-8 w-8' onClick={handleClick}>
            <IoMdArrowRoundForward className='h-full w-full' />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SiteUrl;
